import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Fab,
  Link,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSiteWorkspaceContext } from "./SiteWorkspaceContext";
import React from "react";
import { ViewWithBreadcrumbs } from "./ViewWithBreadcrumbs";
import moment from "moment";

type SiteWorkspaceDetailProps = {};

const SITES = "Sites";
const SETTINGS = "Settings";

type SiteWorkspaceDetailTab = typeof SITES | typeof SETTINGS;

export const SiteWorkspaceDetail: React.FC<SiteWorkspaceDetailProps> = () => {
  const navigate = useNavigate();
  const {
    siteWorkspaceData,
    loading,
    workspaceId,
    workspaceListData,
    workspaceListLoading,
  } = useSiteWorkspaceContext();

  const [activeTab, setActiveTab] =
    React.useState<SiteWorkspaceDetailTab>(SITES);

  const workspaceSelector = (
    <Select
      value={workspaceId}
      onChange={(e) =>
        navigate(`/app/sites/workspaces/${e.target.value as string}`)
      }
    >
      {workspaceListData?.viewer.user.siteWorkspaceUserAssociations.map(
        (siteWorkspaceUserAssociation) => (
          <MenuItem
            key={siteWorkspaceUserAssociation.siteWorkspace.id}
            value={siteWorkspaceUserAssociation.siteWorkspace.id}
          >
            {siteWorkspaceUserAssociation.siteWorkspace.title}
          </MenuItem>
        )
      )}
    </Select>
  );

  if (loading) return <CircularProgress />;

  const sitesTab = (
    <Box paddingY={2}>
      <Box>
        {!siteWorkspaceData?.siteWorkspace.sites.length && (
          <Alert severity="info">
            There are no sites for this workspace. Create one below.
          </Alert>
        )}
      </Box>
      {siteWorkspaceData?.siteWorkspace.sites.map((site) => (
        <Box display={"flex"} flexDirection={"row"} key={site.id} marginBottom={2}>
          <Box>
            <Typography variant={"h5"}>
              {site.title} ({site.type})
            </Typography>
            <Typography>{site.description}</Typography>
          </Box>

            <Button
              onClick={() =>
                navigate(`/app/sites/workspaces/${workspaceId}/site/${site.id}`)
              }
              variant="outlined"
              size="small"
            >
              Manage
            </Button>
        </Box>
      ))}
    </Box>
  );

  const addSiteFab = (
    <Tooltip title="Create new site">
      <Fab
        color="primary"
        aria-label="add"
        onClick={() =>
          navigate(`/app/sites/workspaces/${workspaceId}/create-site`)
        }
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
        }}
      >
        <AddOutlined />
      </Fab>
    </Tooltip>
  );

  const settingsTab = (
    <Box>
      <Box>
        <Typography variant="h4" marginY={3}>Workspace Settings</Typography>
        <Typography variant="h5" marginY={3} gutterBottom>
          Members
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Email</strong>
                </TableCell>
                <TableCell>
                  <strong>Added to Workspace</strong>
                </TableCell>
                <TableCell>
                  <strong>Role</strong>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            {siteWorkspaceData?.siteWorkspace.userAssociations.map(
              (userAssociation) => (
                <TableRow key={userAssociation.id}>
                  <TableCell>{userAssociation.user.emailAddress}</TableCell>
                  <TableCell>
                    {moment(parseInt(userAssociation.created, 10)).fromNow()}
                  </TableCell>
                  <TableCell>Owner</TableCell>
                  <TableCell>
                    <Button
                      disabled
                      variant="outlined"
                      size="small"
                      color="warning"
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              )
            )}
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );

  return (
    <>
      {addSiteFab}
      <ViewWithBreadcrumbs
        breadcrumbs={[
          <Link key="sites" underline="none" color="inherit">
            <Typography variant="h4" fontWeight={400}>Sites</Typography>
          </Link>,
          <Link key={`sites/workspaces/${siteWorkspaceData?.siteWorkspace?.id}`} underline="none" color="inherit">
            <Typography variant="h4">{siteWorkspaceData?.siteWorkspace?.title}</Typography>
          </Link>,
        ]}
        rightCta={<span/>}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
          >
            <Tab value={SITES} label="Sites" />
            <Tab value={SETTINGS} label="Settings" />
          </Tabs>
        </Box>
        <Box>{activeTab === SITES && sitesTab}</Box>
        <Box>{activeTab === SETTINGS && settingsTab}</Box>
      </ViewWithBreadcrumbs>
    </>
  );
};
