import {
  Box,
  Button,
  Card,
  CircularProgress,
  Link,
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Typography,
} from "@mui/material";
import {BaseBreadcrumbs} from "./SiteBreadcrumbs";
import {useSiteWorkspaceContext} from "./SiteWorkspaceContext";
import {useParams} from "react-router-dom";
import {gql} from "../__generated__";
import {useQuery} from "@apollo/client";
import {InfoTable} from "./Debug";
import moment from "moment";
import {useConfigContext} from "../webappConfig";
import React from "react";
import {ViewWithBreadcrumbs} from "./ViewWithBreadcrumbs";

type SiteDetailProps = {};

const SITE_QUERY = gql(`
query SiteQuery($id: String!) {
  site(id: $id) {
    id
    created
    slug
    stats {
      hits
    }
    type
    title
    scale
    description
    domains {
      id
      domainName
    }
    siteWorkspace {
      id
      slug
      title
      userAssociations {
        id
        created
        user {
          id
          emailAddress
        }
      }
    }
    ... on HostedSite {
      currentLiveDeployment {
        id
        created
        bundle {
          id
          hash
        }
      }
    }
    ... on ProxySite {
      url
    }
  }
}
`);

export const SiteDetail: React.FC<SiteDetailProps> = (props) => {
  const {
    webAppConfig: {environment},
  } = useConfigContext();
  const ENVIRONMENT = environment();
  const {siteWorkspaceData, loading, workspaceId} = useSiteWorkspaceContext();
  const {siteId} = useParams();
  const {data: siteData, loading: siteLoading} = useQuery(SITE_QUERY, {
    variables: {
      id: siteId ?? "",
    },
    skip: !siteId,
    fetchPolicy: "no-cache",
  });

  if (!siteWorkspaceData || loading || siteLoading) return <CircularProgress/>;
  if (!siteData) return <span/>;

  return (
    <ViewWithBreadcrumbs
      breadcrumbs={[
        <Link key="sites" href={"/app/sites"} underline="none" color="inherit">
          <Typography variant="h4" fontWeight={300}>Sites</Typography>
        </Link>,
        <Link key={"workspace"} href={`/app/sites/workspaces/${siteWorkspaceData?.siteWorkspace?.id}`} underline="none" color="inherit">
          <Typography fontWeight={300} variant="h4">{siteWorkspaceData?.siteWorkspace?.title}</Typography>
        </Link>,
        <Typography variant="h4">{siteData?.site?.title}</Typography>
      ]}
      rightCta={<span/>}
    >
      <Box>
        <Typography variant={"h5"} marginY={2} >Basic Information</Typography>
        <Box style={{width: 600}}>
          <InfoTable
            rows={[
              ["ID", <>{siteData?.site?.id}</>],
              [
                "Created",
                <>{moment(parseInt(siteData?.site.created, 10)).fromNow()}</>,
              ],
              ["Title", siteData.site.title],
              ["Description", siteData.site.description],
              ["Slug", siteData.site.slug],
              ["Scale", siteData.site.scale ?? "0"],
              [
                "Type",
                <Box>
                  <Box>{siteData.site.type}</Box>
                  <Box>
                    {siteData.site.__typename === "HostedSite" && (
                      <Box style={{width: 600}}>
                        <InfoTable
                          rows={[
                            [
                              "Latest deployment",
                              <>
                                {siteData?.site?.currentLiveDeployment?.created
                                  ? moment(
                                    parseInt(
                                      siteData?.site?.currentLiveDeployment
                                        ?.created ?? "",
                                      10
                                    )
                                  ).fromNow()
                                  : "(none)"}
                              </>,
                            ],
                          ]}
                        />
                      </Box>
                    )}

                    {siteData.site.__typename === "ProxySite" && (
                      <Box style={{width: 600}}>
                        <InfoTable
                          rows={[["Proxy URL", <>{siteData?.site?.url}</>]]}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>,
              ],
            ]}
          />
        </Box>
      </Box>

      <Box>
        <Box justifyContent="space-between" display="flex" flexDirection="row" alignItems="center">
          <Typography marginY={2} variant={"h5"}>Domains</Typography>
          <Button disabled variant="contained">
            Add new domain
          </Button>
        </Box>

        <TableContainer component={Paper}>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Type</strong>
              </TableCell>
              <TableCell>
                <strong>URL</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableRow>
            <TableCell>Default (created by MirrorTab)</TableCell>
            <TableCell>
              <Link
                href={`https://${ENVIRONMENT}.env.mirrortab.com/site/${siteData.site.slug}/`}
              >
                {`https://${ENVIRONMENT}.env.mirrortab.com/site/${siteData.site.slug}/`}
              </Link>
            </TableCell>
            <TableCell>
              <Button variant="outlined" color="error" size="small" disabled>
                Delete
              </Button>
            </TableCell>
          </TableRow>
        </TableContainer>
      </Box>
    </ViewWithBreadcrumbs>
  );
};
