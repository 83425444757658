/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\nmutation CreateHostedSiteMutation($title: String!, $description: String, $workspaceId: String!, $scale: Int!) {\n  createHostedSite(input: { title: $title, description: $description, workspaceId: $workspaceId, scale: $scale}) {\n    created\n    site {\n      id\n      title\n      description\n      scale\n      siteWorkspace {\n        id\n      }\n    }\n    error\n  }\n}\n": types.CreateHostedSiteMutationDocument,
    "\nmutation CreateProxySiteMutation($title: String!, $description: String, $workspaceId: String!, $proxyUrl: String!, $scale: Int!) {\n  createProxySite(input: { title: $title, description: $description, workspaceId: $workspaceId, proxyUrl: $proxyUrl, scale: $scale}) {\n    created\n    site {\n      id\n      title\n      description\n      scale\n      siteWorkspace {\n        id\n      }\n    }\n    error\n  }\n}\n": types.CreateProxySiteMutationDocument,
    "\nquery SiteQuery($id: String!) {\n  site(id: $id) {\n    id\n    created\n    slug\n    stats {\n      hits\n    }\n    type\n    title\n    scale\n    description\n    domains {\n      id\n      domainName\n    }\n    siteWorkspace {\n      id\n      slug\n      title\n      userAssociations {\n        id\n        created\n        user {\n          id\n          emailAddress\n        }\n      }\n    }\n    ... on HostedSite {\n      currentLiveDeployment {\n        id\n        created\n        bundle {\n          id\n          hash\n        }\n      }\n    }\n    ... on ProxySite {\n      url\n    }\n  }\n}\n": types.SiteQueryDocument,
    "\n  query GetSites {\n    viewer {\n      user {\n        id\n      }\n    }\n  }\n": types.GetSitesDocument,
    "\nquery GetSiteWorkspace($id: String!) {\n    siteWorkspace(id: $id) {\n        id\n        title\n        userAssociations {\n          id\n          created\n          user {\n            id\n            emailAddress\n            firstName\n            lastName\n          }\n        }\n        sites {\n          id\n          stats {\n            hits\n          }\n          slug\n          type\n          title\n          description\n          domains {\n            id\n            domainName\n          }\n          ... on HostedSite {\n            currentLiveDeployment {\n              id\n              bundle {\n                id\n                hash\n              }\n            }\n          }\n          ... on ProxySite {\n            url\n          }\n        }\n    }\n}": types.GetSiteWorkspaceDocument,
    "\nquery GetSiteWorkspaces {\n    viewer {\n        user {\n        firstName\n        lastName\n        emailAddress\n        id\n        siteWorkspaceUserAssociations {\n            id\n            created\n            siteWorkspace {\n              id\n              title\n            }\n        }\n      }\n    }\n}": types.GetSiteWorkspacesDocument,
    "\n  mutation CreateWorkspace($title: String!) {\n    createSiteWorkspace(input: { title: $title }) {\n      created\n      workspace {\n        id\n        title\n      }\n      error\n    }\n  }\n": types.CreateWorkspaceDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation CreateHostedSiteMutation($title: String!, $description: String, $workspaceId: String!, $scale: Int!) {\n  createHostedSite(input: { title: $title, description: $description, workspaceId: $workspaceId, scale: $scale}) {\n    created\n    site {\n      id\n      title\n      description\n      scale\n      siteWorkspace {\n        id\n      }\n    }\n    error\n  }\n}\n"): (typeof documents)["\nmutation CreateHostedSiteMutation($title: String!, $description: String, $workspaceId: String!, $scale: Int!) {\n  createHostedSite(input: { title: $title, description: $description, workspaceId: $workspaceId, scale: $scale}) {\n    created\n    site {\n      id\n      title\n      description\n      scale\n      siteWorkspace {\n        id\n      }\n    }\n    error\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation CreateProxySiteMutation($title: String!, $description: String, $workspaceId: String!, $proxyUrl: String!, $scale: Int!) {\n  createProxySite(input: { title: $title, description: $description, workspaceId: $workspaceId, proxyUrl: $proxyUrl, scale: $scale}) {\n    created\n    site {\n      id\n      title\n      description\n      scale\n      siteWorkspace {\n        id\n      }\n    }\n    error\n  }\n}\n"): (typeof documents)["\nmutation CreateProxySiteMutation($title: String!, $description: String, $workspaceId: String!, $proxyUrl: String!, $scale: Int!) {\n  createProxySite(input: { title: $title, description: $description, workspaceId: $workspaceId, proxyUrl: $proxyUrl, scale: $scale}) {\n    created\n    site {\n      id\n      title\n      description\n      scale\n      siteWorkspace {\n        id\n      }\n    }\n    error\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery SiteQuery($id: String!) {\n  site(id: $id) {\n    id\n    created\n    slug\n    stats {\n      hits\n    }\n    type\n    title\n    scale\n    description\n    domains {\n      id\n      domainName\n    }\n    siteWorkspace {\n      id\n      slug\n      title\n      userAssociations {\n        id\n        created\n        user {\n          id\n          emailAddress\n        }\n      }\n    }\n    ... on HostedSite {\n      currentLiveDeployment {\n        id\n        created\n        bundle {\n          id\n          hash\n        }\n      }\n    }\n    ... on ProxySite {\n      url\n    }\n  }\n}\n"): (typeof documents)["\nquery SiteQuery($id: String!) {\n  site(id: $id) {\n    id\n    created\n    slug\n    stats {\n      hits\n    }\n    type\n    title\n    scale\n    description\n    domains {\n      id\n      domainName\n    }\n    siteWorkspace {\n      id\n      slug\n      title\n      userAssociations {\n        id\n        created\n        user {\n          id\n          emailAddress\n        }\n      }\n    }\n    ... on HostedSite {\n      currentLiveDeployment {\n        id\n        created\n        bundle {\n          id\n          hash\n        }\n      }\n    }\n    ... on ProxySite {\n      url\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetSites {\n    viewer {\n      user {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetSites {\n    viewer {\n      user {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetSiteWorkspace($id: String!) {\n    siteWorkspace(id: $id) {\n        id\n        title\n        userAssociations {\n          id\n          created\n          user {\n            id\n            emailAddress\n            firstName\n            lastName\n          }\n        }\n        sites {\n          id\n          stats {\n            hits\n          }\n          slug\n          type\n          title\n          description\n          domains {\n            id\n            domainName\n          }\n          ... on HostedSite {\n            currentLiveDeployment {\n              id\n              bundle {\n                id\n                hash\n              }\n            }\n          }\n          ... on ProxySite {\n            url\n          }\n        }\n    }\n}"): (typeof documents)["\nquery GetSiteWorkspace($id: String!) {\n    siteWorkspace(id: $id) {\n        id\n        title\n        userAssociations {\n          id\n          created\n          user {\n            id\n            emailAddress\n            firstName\n            lastName\n          }\n        }\n        sites {\n          id\n          stats {\n            hits\n          }\n          slug\n          type\n          title\n          description\n          domains {\n            id\n            domainName\n          }\n          ... on HostedSite {\n            currentLiveDeployment {\n              id\n              bundle {\n                id\n                hash\n              }\n            }\n          }\n          ... on ProxySite {\n            url\n          }\n        }\n    }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetSiteWorkspaces {\n    viewer {\n        user {\n        firstName\n        lastName\n        emailAddress\n        id\n        siteWorkspaceUserAssociations {\n            id\n            created\n            siteWorkspace {\n              id\n              title\n            }\n        }\n      }\n    }\n}"): (typeof documents)["\nquery GetSiteWorkspaces {\n    viewer {\n        user {\n        firstName\n        lastName\n        emailAddress\n        id\n        siteWorkspaceUserAssociations {\n            id\n            created\n            siteWorkspace {\n              id\n              title\n            }\n        }\n      }\n    }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateWorkspace($title: String!) {\n    createSiteWorkspace(input: { title: $title }) {\n      created\n      workspace {\n        id\n        title\n      }\n      error\n    }\n  }\n"): (typeof documents)["\n  mutation CreateWorkspace($title: String!) {\n    createSiteWorkspace(input: { title: $title }) {\n      created\n      workspace {\n        id\n        title\n      }\n      error\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;